import React, { Component } from 'react';
import { Link } from "gatsby";
import Date from './date'

class RecentPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isBlogLoaded: false,
            blogContent: [],
        }
    }

    async componentDidMount() {
        this.getAllBlog();
    }

    componentWillReceiveProps(nextProps) {
        this.getAllBlog();
    }

    getAllBlog(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-category-name/" + this.props.category)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isBlogLoaded: true,
                        blogContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const { error, isSingleLoaded, isBlogLoaded, items, blogContent, isCategoryLoaded, categoryContent } = this.state;

        return (
            <React.Fragment>
                <div className="inner">
                    <ul>
                        {isBlogLoaded ?
                            <React.Fragment>
                                {blogContent.data.slice(0, 3).map(item => (
                                    <li>
                                        <p><Link className="data" to={"/blog-details/" + item.slug}> {item.title.slice(0, 40) + "..."}  </Link></p>
                                        <p className="author"><Link to={"/blog-by-author/"+item.created_by}>{item.created_by}</Link></p>
                                        <p className="date"> <Date date={item.publish_date}></Date></p>
                                    </li>
                                ))}
                            </React.Fragment>
                            : ''}
                            <li className="see_all_outer">
                            <Link className="see_all" to={"/blog-by-category/" + this.props.category}> See all </Link>
                            </li>
                    </ul>
                    {/* see_all */}
                </div>
            </React.Fragment>
        )
    }
}

export default RecentPost;
