import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/blogItem";
import BlogCategory from "../components/blog-category";
import RecentPost from '../components/recent-post';
import Nav from '../components/nav';
import moment from 'moment';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isSingleLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],

            isCategoryLoaded: false,
            categoryContent: [],
            tag: '',

            category_count: null,
            year_count: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let CMSId = id.params["*"];
        this.getDetailsById(CMSId);
        this.getAllCategoryByType();
        // this.getAllBlog();
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let id = nextProps.params["*"];
        this.getDetailsById(id);
        this.getAllCategoryByType();
        // this.getAllBlog();
    }

    getAllBlog() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
                    console.log("filteredDates", filteredDates)

                    // this.setState({
                    //     isBlogLoaded: true,
                    //     blogContent: filteredDates
                    // });

                    let result2 = result.data.reduce(function (r, a) {
                        r[a.category] = r[a.category] || [];
                        r[a.category].push(a);
                        return r;
                    }, Object.create(null));
                    // console.log("result2", result2);


                    let result2publish_date = result.data.reduce(function (r, a) {
                        // let year = moment(a.publish_date,"YYYY-MM-DD").year()
                        // console.log("year", year + a.publish_date)
                        r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [];
                        // console.log(r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [])
                        r[moment(a.publish_date, "YYYY-MM-DD").year()].push(a);
                        // console.log(a)
                        return r;
                    }, Object.create(null));
                    // console.log("result2publish_date", result2publish_date);



                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates,
                        category_count: result2,
                        year_count: result2publish_date
                    });

                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    getAllCategoryByType() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    getDetailsById(id) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-slug/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    let array = result.data.tag?.split(',');
                    let uniqTag = [...new Set(array)];
                    console.log("arr", uniqTag)

                    this.setState({
                        isSingleLoaded: true,
                        items: result,
                        tag: uniqTag
                    });
                },
                (error) => {
                    this.setState({
                        isSingleLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const { isSingleLoaded, items, isCategoryLoaded, categoryContent, tag,
            category_count,
            year_count ,
            isBlogLoaded
         } = this.state;

        return (
            <Layout>
                {isSingleLoaded ?
                    <Nav
                        active="blog"
                        pageTitle={items.data?.title}
                        title={items.data?.document_title}
                        description={items.data?.meta_description}
                        keywords={items.data?.meta_tag}
                    ></Nav>
                    : ''
                }
                <Intro title="Our Blog"
                    search="true"
                    searchType="blog"
                    description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>


                <div className="main-container" id="blog-category-page">
                    <div className="container" id="blog_page_details_single">


                        {isSingleLoaded ?
                            <div className="blog-category-top">
                                <span className="blog-category-top-link" title={items.data.category} >
                                    <span dangerouslySetInnerHTML={{ __html: items.data.category }}></span> </span>
                            </div>
                            : ''}

                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <div className="blog-container">

                                    {isSingleLoaded ?
                                        <React.Fragment>
                                            <BlogItem
                                                category="CORPORATE LAW"
                                                category_show="false"
                                                blog_details="true"
                                                title={items.data.title}
                                                author={items.data.created_by}
                                                date={items.data.publish_date}
                                                short_content={items.data.description}
                                                slug={items.data.slug}
                                                tag={tag}
                                            >
                                            </BlogItem>
                                        </React.Fragment>

                                        : ''}

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="recent-post">


                                    {isSingleLoaded ?
                                        <React.Fragment>
                                            <h1 className="cat_upper">
                                                <span dangerouslySetInnerHTML={{ __html: items.data.category }}></span>
                                            </h1>
                                            <RecentPost category={items.data.category}></RecentPost>
                                        </React.Fragment>
                                        : ''}
                                </div>
                                {isCategoryLoaded & isBlogLoaded ?
                                <BlogCategory
                                    category_count={category_count}
                                    year_count={year_count}
                                    className={"col-md-3 col-sm-12"} categoryContent={categoryContent}></BlogCategory>
                                : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;
